/* ------------------------------------
    |           Login Page               |
     ------------------------------------  */

.login-page-section {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.login-page-section .image-section {
  width: 60%;
}

.login-page-section .image-section img {
  max-width: 100%;
  width: 100%;
  height: 100vh;

  object-fit: cover;
}

.logo-of-login {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo-of-login img {
  width: 100px;
  height: auto;
  margin-bottom: 50px;
}

.login-page-section .login-form-section {
  width: 40%;
  display: flex;
  gap: 20px;

  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form-section .wrapper-form {
  width: 60%;
}

.FormikInput {
  margin: 8px 0;
  border-radius: 8px;
  background-color: white;
  font-size: 13px;
}

.login-with-google {
  margin-top: 20px;
}

.forget-password {
  color: #0f6274;
}

.login-page-section .sign-up-goes {
  position: fixed;
  bottom: 0;
  right: 13%;
}

/* ------------------------------------
    |           Main wrapper page              |
     ------------------------------------  */

.main-wrapper-body {
  width: 100%;
  overflow: hidden;
  display: flex;
}

.main-pages {
  padding-left: 7%;
  width: 100%;
  padding-right: 1%;
  padding-top: 10px ;
}

/* ------------------------------------
    |           Sidebar Page               |
     ------------------------------------  */
.sidebar-setion {
  width: 6%;
  height: 100vh;
  padding: 4px;
  position: fixed;
  background-color: white;
}

.sidebar-header .header-logo {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.sidebar-header .header-logo img {
  width: 70px;
  padding-top: 20px;

  height: auto;
}

.list-menu-icons {
  height: 80vh;
}

.app-menu-sidebar:hover{
  color: #0f6274;

}

.plan-svg path:hover{
  fill: #0f6274;

}

.list-menu-icons ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  color: #a9b6c2;
  font-size: 12px;
  text-decoration: none;
}

.list-menu-icons ul li .active {
  color: #0f6274;
}

 .Logout-menu {
  color: #0f6274;
}

 .profileMenu{
  color: #0f6274;
}

.list-menu-icons ul li .sidebar-icons {
  font-size: 18px;
}

/* ------------------------------------
    |           dashboard Page               |
     ------------------------------------  */

.dashboard-section {
  width: 100%;
  padding-top: 10px;
}

.dashboard-section .dashboard-header {
  /* width: 100%;
  display: flex; */
  justify-content: space-between;
  align-items: center;
}

/* .button-group button {
  
} */
.dashbord-date {
  padding: 12px 0;
}

.dashbord-date div {
  display: flex;
  /* margin: 10px; */
  /* background-color: white; */

  /* padding: 15px; */
  font-weight: 500;
}

.dashbord-date div span {
  color: #a9b6c2;
  font-weight: 400;
}

.dashboard-section .dashoard-button-group {
  padding: 14px 0 14px 0;
}

.alert-dot-status{
  background: red;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  position: absolute;
  right: -3px;
  top: -2px;

}

/* ================================= */
/* option styling */
.select-selected {
  background-color: red;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.select-items {
  position: absolute;
  background-color: red;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.farm-item{
  cursor: pointer;
}

.date-item , .month-item , .year-item {
  cursor: pointer;
  padding: 10px 8px;
  font-weight: 600;
  box-shadow: 0px 0px 16px -14px rgb(0 0 0 / 75%);
}

.date-item span{
  font-weight: 400;
}

.assign-device-sensor:hover{
border-bottom: 1px solid #656d74;
}

.device-phase-title{
  color: #0f6274;
  font-size: 13px;
  margin: 0 0 0 -100px;
}



/* =============================== */
/* .alerts-and-weather-card {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 30px;
} */

/* alert card */
.alert-card {
  background-color: #0f6274;
  border-radius: 25px;
  color: white;
  height: 250px;
  /* display: grid;
  grid-template-columns: 300px 1fr; */
}

.alert-card-not-user-allowed{
  background-color: #0f62748a;
  border-radius: 25px;
  color: white;
  height: 250px;
  display: grid;
  grid-template-columns: 300px 1fr;
}

.alert-new-count{
  color: white;
  margin-left: 5px;
  margin-top: 2px;
  font-size: 11px;
  width: 25px !important;
  height: 25px;
  border-radius: 50% !important;
  background: #F83E03;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: rotateY(45deg); */
}

.alert-new-count-dashboard{
  color: white;
  margin-left: 5px;
  font-size: 10px;
  width: 23px !important;
  height: 23px;
  border-radius: 50% !important;
  background: #F83E03;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}


/* devices slider on the  */

.parent_slider {
  width: 1224px; /* or any other desired width */
  overflow-x: hidden; /* to enable horizontal scrolling if necessary */
  white-space: nowrap;
  display: flex;
  margin-bottom: 16px;
  min-width: 1024px;
}

.parent_slider  .card-slider {

  background-color: white;
  margin: 0 15px 0 0;
  float:left;
  border-radius: 8px;
  font-size:15px;

  padding: 10px 20px;
  cursor: pointer;
}


.description-side-alert-card .description-alert-card-header p {
  font-size: 14px;
  font-weight: 700;
}

.description-side-alert-card .description-alert-card-header p span {
  font-size: 12px;
  font-weight: 400;
  color: #a9b6c2;
}

.description-side-alert-card {
  /* border-left: 1px solid rgba(169, 182, 194, 0.4); */
  padding: 5px 0 0 10px;
}

.weather-content {
  padding: 6px;
}

.weather-logo-section {
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
  font-size: 18px;
}

.weather-header {
  padding: 0px 10px 10px 10px;
}

.weather-body,
.weather-footer {
  padding: 5px 20px;
}

.weather-footer .first-one-card {
  background: #0f6274;
  padding: 5px 15px;
  width: 30%;
  height: 70px;
  border-radius: 10px;
}

.icons-card {
  padding: 0px 0;
}

.weather-footer .one-card {
  background: white;
  padding: 5px 10px;
  height: 70px;
  width: 30%;
  border-radius: 10px;
}


.month-select,
.year-select{
  border: none;
  /* background: white; */
  /* padding: 3px; */
  border-radius: 6px; 
  margin-right: 5px;
  outline: none;
  width: 100px;
}
/* ------------------------------------
    |           farm Page               |
     ------------------------------------  */

.farm-section {
  width: 100%;
  padding-top: 10px;
}

.farm-section .farms-card-section {
  padding-top: 30px;
  padding-bottom: 20px;
}

.farm-card {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 16px -14px rgb(0 0 0 / 75%);
}

/* ------------------------------------
    |           all All-farm-section Page               |
     ------------------------------------  */

.All-farm-section {
  width: 100%;
  padding-top: 10px;
}

/* .table-all-farms .wrapper {
  /* background-color: white;
} */

.wrapper {
  margin-top: 10px;

  border-radius: 5px;
  padding: 10px;
  background-color: white;
}

/* ------------------------------------
    |           alert Page               |
     ------------------------------------  */

.alert-section {
  width: 100%;
  padding-top: 10px;
}

/* alert body */
.alert-section-body {
  padding: 20px 0 0 0;
}

.alert-body-table {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.alert-body-table .alert-header-buttons-group {
  position: absolute;
  right: 30px;
  top: 20px;
}

.repeat-time-schedule {
  border-bottom: 1px solid rgba(88, 90, 92, 0.41);
  padding: 30px;
  padding-left: 20px;
  margin-bottom: 10px;
}

.inform {
  border-bottom: 1px solid rgba(88, 90, 92, 0.41);
  padding: 20px;
  padding-left: 20px;
}

.tab-panel ul li button {
  border: 1px solid #eeecec !important;
}

/* ------------------------------------
    |           Automate-section Page               |
     ------------------------------------  */
.Automate-section {
  width: 100%;
 
  padding-top: 10px;
}

.Automate-section .automate-body-section {
  padding-top: 20px;
}

.automate-body-section .automate-table {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.device-group-automate div{
  width: 33.33%; /* Each div will take one-third of the width */
  box-sizing: border-box;
}
.device-group-automate{
  align-items: center;
}

.group-select-aut div{
  width:195px !important;
}

/* ------------------------------------
    |           seeting Page               |
     ------------------------------------  */
.setting-section {
  width: 100%;
  padding-top: 10px;
  position: relative;
}

.setting-body-section {
  padding: 20px 0 0 0;
}

.setting-table {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.setting-table ul li button {
  border: 1px solid #eeecec !important;
}

.scrollable-port{
  height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* global button */
.add-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
}
.setting-input{
  padding: 4px 11px !important;

}

/* ------------------------------------
    |           Login Page               |
     ------------------------------------  */
/* ------------------------------------
    |           Logout Page               |
     ------------------------------------  */
.logout-out-body {
  padding: 20px 0 0px 0;
  border-radius: 10px;
  /* border: 1px solid rgba(88, 90, 92, 0.41); */
}

.logout-out-body h2 {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
}

.logout-buttons-group {
  /* border: 1px solid rgba(88, 90, 92, 0.41); */
  border-radius: 5px;
}

.logout-btn {
  padding-top: 10px;
  padding-bottom: 10px;
}

.logout-text {
  font-weight: 700;
  padding-left: 20px;
}

/* .div-sub-title {
  border-bottom: 1px solid rgba(88, 90, 92, 0.41);
} */

/* dashbord card icon */
.first-icon {
  background-color: #0f6274 !important;
}

.second-icon {
  background-color: #3ec7e4 !important;
}

.third-icon {
  background-color: #fc9035 !important;
}


.Selected-farm{
  padding: 5px;
  border-radius: 8px;
  background: #216375;
  color: white;
}
.farmItem{
  padding: 5px;
  border-radius: 8px;
  background: white;

}

/*========================= forgot Page Css======================= */

.Forgot_section {
  width: 100%;
  /* max-width: 100%; */
  height: 100vh;
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* justify-content: center; */
}

.custom-font-size {
  font-size: 16px;
}

.Forgot_section .image-section {
  width: 60%;
  height: 100vh;
}

.Forgot_section .image-section img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.Forgot_section .list-section {
  width: 40%;
  display: flex;
  height: 100vh;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}

.Forgot_section .list-section .user-creation-content {
  width: 60%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  /* border: 1px solid #0f6274; */
}

.Forgot_section .logo-section {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f6274;
  margin-bottom: 20px;
  font-size: 25px;
}

.logo-section::before,
.logo-section::after {
  content: "";
  display: block;
  border-radius: 50%;
  /* height: 60%; */

  position: absolute;
}

.logo-section::before {
  width: 5%;
  padding-bottom: 5%;
  background-color: #e9eff1;
  z-index: -1000;
}

.logo-section::after {
  width: 3%;
  padding-bottom: 3%;
  background-color: #d3e0e3;
  z-index: -100;
}

.forgot-password-farm {
  width: 100%;
}

.Heading-forgot {
  font-size: 28px;
}

.sub_title-forgot {
  font-size: 18px;
  color: #656d74;
}

/* ============================================== */
/*                 resetPage CSS                   */
/* =============================================== */

.resetInput {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 5px 20px 5px 10px;
  display: flex;
  align-items: center;
}

.reset_section_page {
  display: flex;
  width: 100%;
  height: 100vh;
}



.reset_section_page .image-section {
  width: 60%;
  height: 100vh;
}

.reset_section_page .image-section img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.reset_section_page .list-section {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.reset_section_page .list-section .user-creation-content {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.user-creation-content .logo-section {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f6274;
  margin-bottom: 30px;
  font-size: 25px;
}

/* ================================================================== */
/* -------------------------Login /Forgot / resetPassword / signup  media query for all devices---------------- */
/* ======================================================================= */
@media (max-width: 768px) {
  /* reset page */
  .reset_section_page .list-section .user-creation-content {
    width: 85%;
  }
  /* login */
  .login-form-section .wrapper-form {
    width: 85%;
  }

  /* forgot page */
  /* .Forgot_section .list-section .user-creation-content {
    /* width: 85%; */

  .reset_section_page .image-section {
    width: 0%;
    
  }

  .reset_section_page  .list-section  {
    width: 100% ;
    /* background-color: rgba(243, 228, 228, 0.288); */

  }
  .reset_section_page  .list-section  .user-creation-content {
    width: 100% ;
    margin: auto;
    /* background-color: rgba(211, 202, 202, 0.486); */
    
  }

  .Forgot_section .logo-section {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0f6274;
    margin-bottom: 10px;
    font-size: 20px;
  }
  
  .logo-section::before,
  .logo-section::after {
    content: "";
    display: block;
    border-radius: 50%;
    /* height: 60%; */
  
    position: absolute;
  }
  
  .logo-section::before {
    width: 20%;
    padding-bottom: 20%;
    background-color: #e9eff1;
    z-index: -1000;
  }
  
  .logo-section::after {
    width: 12%;
    padding-bottom: 12%;
    background-color: #d3e0e3;
    z-index: -100;
  }
}

@media (max-width: 860px) {
  /* reset  page */
  /* .reset_section_page .list-section .user-creation-content {
    width: 90%;
  } */
  /* login */
  .login-form-section .wrapper-form {
    width: 90%;
  }
  .reset_section_page  .list-section  {
    width: 100% ;
    /* background-color: rgba(211, 202, 202, 0.486); */
    
  }
  .reset_section_page  .list-section  .user-creation-content {
    width: 80% ;
    margin: auto;
    
  }

  .Forgot_section .logo-section {
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0f6274;
    margin-bottom: 10px;
    font-size: 22px;
  }
  
  .logo-section::before,
  .logo-section::after {
    content: "";
    display: block;
    border-radius: 50%;
    /* height: 60%; */
  
    position: absolute;
  }
  
  .logo-section::before {
    width: 17%;
    padding-bottom: 17%;
    background-color: #e9eff1;
    z-index: -1000;
  }
  
  .logo-section::after {
    width: 10%;
    padding-bottom: 10%;
    background-color: #d3e0e3;
    z-index: -100;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}

@media (min-width: 1600px) {
}

.Editmode {
  background: #f5f5f5;
  cursor: not-allowed;
}

.invalid-field{
  border-color: #dc3545 !important;
}

.custom-invalid{
  border-color: red  !important;
}
.custom-invalid.ant-select:not(.ant-select-disabled):focus,
.custom-invalid.ant-select:not(.ant-select-disabled):hover {
  border-color: red !important;
}

.custom-invalid .ant-select-selector {
  border-color: red !important;
}


.linkable{
  color:#228be6;
}

.linkable:hover{
  text-decoration: underline;

}

/* .react-multi-carousel-list {
  position: unset !important;
}
 */


 .showerror-register-device{
  color:red;
  margin-left:110px;
 }



 /* Assign Screen */


.Main-select div{
  width: 144px !important;
  height: 34.02px !important;
  /* padding: 10px 10px !important; */
}




