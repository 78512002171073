.global-btn {
  padding: 4px 10px;
  font-size: 16px;
  border-radius: 10px;
  font-weight: 400;
  letter-spacing: 0.6px;
  cursor: pointer !important;
}

.inActive-btn {
  background: "#e9eff1x";
  border: none !important;
  padding: 6px 30px !important;
  cursor: not-allowed !important;
}

/* [type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: not-allowed !important;
} */

.deactiveButton {
  background-color: #4d8291;
  border: 1px solid #4d8291;
  outline: none;
  color: #bfb8b8;
  cursor: not-allowed !important;
}
.deleteDeactive {
  border: 1px solid #ece8e86f;
  color: #a6a1a1;
  cursor: not-allowed !important;
}

.button-sensor {
  width: 80px;
  padding: 4px 10px;
  font-size: 14px;
  border-radius: 10px;
  font-weight: 400;
  letter-spacing: 0.6px;
}

.primary-btn {
  border: 1px solid #0f6274;
  background-color: #0f6274;
  color: white;
}

.assign-btn {
  padding: 5px 20px;
  border-radius: 5px;
  /* box-shadow: ; */
}

.deActive-assign-btn {
  border: 1px solid #eeecec !important;
  background-color: #eeecec !important;
  color: #a6a1a1;
  cursor: not-allowed !important;
}

.deActive-assign-btn:hover {
  color: #a6a1a1;
}

.bg-assign-btn {
  border: 1px solid #0f6274 !important;
  background-color: #0f6274 !important;
  color: white;
}
.bg-assign-btn:hover {
  border: 1px solid #0f6274d1 !important;
  background-color: #0f6274d1 !important;
  color: white;
}

.green-btn {
  border: 1px solid #0f6274;
  color: #0f6274;
  background-color: white;
}

.secondary-btn {
  border: 1px solid rgba(169, 182, 194, 0.5);
  background-color: white;
  color: 000000;
}
.tertary-btn {
  border: 1px solid #83c417;
  background-color: #83c417;
  color: white;
}

.decent-btn {
  border: 1px solid #eeecec;
  background-color: #eeecec;
  color: #a6a1a1;
}

.Errormessage {
  font-size: 12px;
}

.current-plan-status-btn {
  padding: 2px 17px;
  color: white;
  border-radius: 13px;
}
.expired-one {
  background-color: red;
}
.active-one {
  background-color: #83c417;
}

.title {
  font-size: 16px;
  line-height: 16px;
  margin-left: 5px;
  cursor: default;
  letter-spacing: 0.3px;
}

.sub-title {
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
}

.ant-picker-range {
  padding: 8px !important;
}

/* =================graph-card======================== */
.Grapcard-section {
  background-color: white;
  padding: 5px;
  width: 100%;
  box-shadow: 0px 0px 16px -14px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  position: relative;
  top: 0;
  /* overflow: hidden; */
}

.card-heder {
  display: flex;
  /* align-items: center; */
  /* position: absolute; */
  padding: 5px 0 0 10px;
  /* z-index: 100; */
}
.card-heder .icon {
  background-color: #fc9035;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  margin-right: 10px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  height: 30px;
}
.Grapcard-section .description p {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.Grapcard-section .description p span {
  font-size: 12px;
  font-weight: 400;
}

/* serchbar */

.serchbar-section {
  background-color: white;
  padding: 8px;
  width: 25%;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.serch-icon {
  font-size: 20px;
  text-align: center;
  line-height: 20px;
}

.search-input input {
  border: none;
  padding-left: 10px;
  outline: none;
  width: 80%;
}

/* table custom css */

table {
  width: 100%;
  max-width: 100%;
  margin: 8px auto;
  border-collapse: separate;
  border-spacing: 0;
  border-top: 1px solid #eeecec !important;
  vertical-align: center;
}

.table > :not(:last-child) > :last-child > * {
  /* border: none !important; */
  border-color: #eeecec;
}

/* th,
td {
  /* cell */
/* padding: 0.75rem;
  font-size: 0.9375rem;
} */
th {
  /* header cell */
  font-weight: 700;
  text-align: left;
  cursor: pointer;

  /* color: #272838; */
  /* border-bottom: 2px solid #eb9486; */

  /* position: sticky;
  top: 0; */
  /* background-color: #f9f8f8; */
}
.table thead {
  border-style: none !important;
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  border-collapse: separate;
}

td {
  /* body cell */
  color: #000000;
  border: none;
}

/* modal css */

.ant-modal .ant-modal-content {
  /* border: 1px solid red; */
  padding: 0px 0px !important;
}

.ant-btn-primary {
  background-color: #0f6274 !important;
  /* font-weight: 600; */
  color: white;
}

.ant-btn-default:hover {
  color: #0f6274 !important;
  border: 1px solid #0f6274 !important;
}

.ant-modal-body form {
  padding: 10px;
}

.ant-modal-body form .form_wraper {
  border: 1px solid #bcb5b5;
  border-radius: 10px;
  padding: 15px 2px;
}

/* .ant-modal-body form label { */
/* border-bottom: 1px solid #bcb5b5; */
/* margin-bottom: 20px;
  padding: 0 15px 20px 15px; */
/* } */

.form-modal-btn {
  padding: 0 15px 10px 15px;
}

/* ant switch bttn */
.ant-switch.ant-switch-checked {
  background-color: #0f6274 !important;
}

/* tabs header */

.setting-table .nav-tabs,
.alert-body-table-header .nav-tabs {
  border: none !important;
}
.alert-body-table-header .nav-tabs .nav-item button,
.setting-table .nav-tabs .nav-item button {
  display: block;
  margin: 0 12px !important;
  padding: 0.5rem 1rem !important;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;

  /* border: 1px solid rgba(169, 182, 194, 0.5) !important; */
  /* background-color: white !important; */
  color: #000000;
}

.setting-table .nav-tabs .nav-link.active,
.alert-body-table-header .nav-tabs .nav-link.active {
  background-color: #0f6274 !important;
  color: White;
}

/* .alert-body-table-header .nav-tabs .nav-link:hover {
  background-color: #0f6274 !important;
  color: white !important;
} */

/* icon common */

.humidity-icon {
  background-color: #3ec7e4;
  border-radius: 50%;
  color: white;
  padding: 5px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}
.sunLight-icon {
  background-color: #fc9035;
  border-radius: 50%;
  color: white;
  padding: 5px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.humidity-icon {
  background-color: #0f6274;
  color: white;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

/* logout */

.logout-btn {
  background-color: white;
  border: none;
  outline: none;
}

/* button stattus */

.button-status {
  position: relative;
  overflow: hidden;
  object-fit: contain;
  width: 150px;
}
.button-status .sensor-online-status {
  position: absolute;
  right: 0;
  top: 0;
  width: 7px !important;
  height: 7px !important;
  background-color: #ff0000;
  border-radius: 100%;
}

.button-status .sensor-nutral-status {
  position: absolute;
  right: 45%;
  top: 0;
  width: 7px;
  height: 7px;
  background-color: #83c417;
  border-radius: 100%;
}

.button-status .sensor-offline-status {
  position: absolute;
  right: 45%;
  top: 0;
  width: 7px;
  height: 7px;
  background-color: #bcb5b5;
  border-radius: 100%;
}

/* icons */

.table-icons {
  font-size: 15px;
  font-weight: 700;
  margin-right: 6px;
}

.Tabs-icons {
  font-size: 18px;
  font-weight: 600;
  margin-right: 3px;
}

.btn-padding {
  padding: 6px 10px;
}

/* alert template */

.message-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 6px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myscrollbar::-webkit-scrollbar {
  display: none;
}

.background {
  border: 1px solid #c7c7c7 !important;
  color: #0f6274 !important;
}

.select-automate {
  width: 500px !important;
}

/*================= profile modal ================================ */

.ProfileInput {
  border: none;
  border-bottom: 1px solid rgba(169, 182, 194, 0.5);
  margin-bottom: 10px;
  color: #000000 !important;
}

.ProfileInput::placeholder {
  color: #000000;
  opacity: 1; /* Firefox */
}

.ProfileInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
}

.ProfileInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
}

.placehodler-custom::placeholder {
  color: rgba(169, 182, 194, 0.753);
}

.ant-modal .ant-modal-close {
  top: 3px !important;
  width: 23px !important;
  height: 26px !important;
  padding-bottom: 7px;
  color: #000000;
  font-size: 14px !important;
}

.ant-modal .ant-modal-title {
  padding: 10px 0 0 12px !important;
}

/* -===dashboard select=======- */
.select-dashboard {
  /* background-color: red !important; */
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 5px;
}
.select-dashboard option {
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 5px;
}

.month-year-card {
  padding: 18.5px;
}

.input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
}

.input-wrapper input[type="file"] {
  display: none;
}

.input-wrapper label {
  display: block;
  cursor: pointer;
}

.input-wrapper img {
  width: 27px;
  height: 28px;
}

/*---------------------------- pagination---------------------------------------- */
.paginate {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px 0;
  width: 105%;
  margin: -10px;
  background: #f1f7f7;
}

/* .paginate::after{
  position: absolute;
  content: "";
  width: 29px;
  height: 34px;
  border: 10px;
  right: -9px;
  bottom: 89px;
  border-radius: 50%;
  box-shadow: 24px 22px 0 10px rgb(241 247 247);
  background-color: transparent;

}

.paginate::before{
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  border: 10px;
  left: 0px;
  bottom: 89px;
  border-radius: 50%;
  box-shadow: -15px 24px 0 10px rgb(241 247 247);
  background-color: transparent;

} */

.paginate ul {
  list-style: none;
  display: flex;
  gap: 20px;
  color: #a9b6c2;
  font-size: 14px;
  padding: 5px;
  font-weight: 700;
}

.paginate ul li a {
  text-decoration: none;
  padding: 5px;
}

/* .paginate ul li a:hover {
  background-color: #f2f7f9;
  box-shadow: 0 0 4px 1px rgba(169, 182, 194, 0.5);
  border-radius: 5px;
} */

.paginate ul .selected {
  color: #0f6274;
}

/* =========tooltip============== */
.apexcharts-tooltip {
  box-shadow: none !important;
}
.apexcharts-tooltip.apexcharts-theme-light {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
}
.arrow_box {
  background-color: none !important;
  padding: 5px;
  position: relative;
}
.number {
  background-color: #162635;
  padding: 5px 20px !important;
  border-radius: 10px !important;
  color: white;
  /* position: absolute; */
  /* right: 0; */
}

.noData-graph {
  height: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noData-graph p {
  color: #aebac6;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #162635;
  margin-left: 40%;
}

.border-anotation {
  border-style: dotted;
  border-width: thick;
}

.react-multi-carousel-list {
  padding: 4px 0 !important;
}

/* Active Date */

.activeDate {
  /* border:1px solid # !important; */
  background-color: #0f6274;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  /* padding-bottom: 5px; */
}

.invlideDate {
  border: 1px solid white;
  background-color: white;
  border-radius: 10px;
  font-size: 14px;
  color: #818a8b44;
  cursor: not-allowed !important;
}

.invlideDate span {
  color: #818a8b44 !important;
}

.dates {
  border: 1px solid white;
  background-color: white;
  border-radius: 10px;
  font-size: 14px;
  color: #0f6274;
  /* padding-bottom: px !important; */
}

.selectedDate {
  background-color: white;
  border-radius: 10px;
}
/* .selectedDate::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #0f6274;
} */

.carousel-container {
  width: 100%;
}

.carousel-item {
  display: block !important;
  margin-right: 0 !important;
}

.react-multiple-carousel__arrow--left {
  left: -40px !important;
}

.react-multiple-carousel__arrow--right {
  right: -40px !important;
}

.react-multiple-carousel__arrow--right::before {
  right: 0px !important;
  text-align: start !important;
  margin-left: 10px !important;
  font-size: 14px !important;
  font-weight: 900;
}

.react-multiple-carousel__arrow--left::before {
  right: -40px !important;
  text-align: start !important;
  margin-left: 5px !important;
  font-size: 14px !important;
  font-weight: 900;
}

.react-multiple-carousel__arrow {
  background: #0f6274 !important;
  min-width: 60px !important;
  min-height: 60px !important;
  border-radius: 50%;
  opacity: 2;
  border: 0 !important;
}

/* ====================all farms data carousel================= */
.all-farms-in-group
  .react-multi-carousel-list
  .react-multiple-carousel__arrow--right::before {
  right: 0px !important;
  text-align: start !important;
  margin-left: 5px !important;
  font-size: 10px !important;
  font-weight: 900;
}

.all-farms-in-group
  .react-multi-carousel-list
  .react-multiple-carousel__arrow--left::before {
  right: -40px !important;
  text-align: start !important;
  margin-left: 3px !important;
  font-size: 10px !important;
  font-weight: 900;
}

.all-farms-in-group .react-multiple-carousel__arrow {
  background: #0f6274 !important;
  min-width: 50px !important;
  min-height: 53px !important;
  border-radius: 100%;
  opacity: 2;
  border: 0 !important;
}

/* active month and month */

.ActiveMonth {
  background-color: #0f6274;
  color: white;
  border-radius: 10px;
  /* width: 50px !important; */
  padding: 19px 5px !important;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.selectedMonth {
  background-color: white;
  color: #000000;
  border-radius: 10px;
  /* width: 50px !important; */
  padding: 10px 5px !important;
  display: flex;
  justify-content: center;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.selectedMonth::after {
  content: "";
  width: 8px;
  height: 8px;
  margin-top: 10px;
  border-radius: 50%;
  background-color: #0f6274;
}

.Month {
  background-color: white;
  color: #000000;
  border-radius: 10px;
  /* width: 50px !important; */
  padding: 19px 5px !important;
  display: flex;
  justify-content: center;
  font-size: 14px !important;
  font-weight: 500 !important;
}

/* form control non-editable */
.form-control:disabled {
  /* background-color: white !important; */
  opacity: 1;
  color: #a69b9b;
}

/* input checked color
 */

/* .checkboxes  {
  & > input{
    border-color: red !important;
    background-color: red !important;
    font-size: 30px !important;
  }
 
} */

/* .tableCheckbox:checked {
  background-color: #e9ecee;
	border: 1px solid #adb8c0;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #99a1a7;
} */

/* ============================================= */
/*      input   checkbox custom css  */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 2px solid #3c6c77;

  border-radius: 5px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="checkbox"]:after {
  content: "\2713";
  font-weight: 900;
  font-size: 16px;
  color: white;
}
input[type="checkbox"]:hover {
  border: 2px solid #02222c;
  /* box-shadow: 0 1px 0 4px rgba(0 ,0 ,0 ,0.1); */
}
input[type="checkbox"]:checked {
  background-color: #4d8291;
}
input[type="checkbox"]:checked:after {
  display: block;
}

/* ==============================end checkbox css============= */

/*====================== setting --sensor -list */
.assign-farm {
  color: #0f6274;
  cursor: pointer !important;
}

/* media query============================== */

@media (min-width: 768px) {
  .alerts-and-weather-card {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 30px;
  }
  /* .alert-card {
    background-color: #0f6274;
    border-radius: 25px;
    color: white;
    height: 250px;
    display: grid;
    grid-template-columns: 200px 1fr;
  } */
}

@media (min-width: 1200px) {
  .alerts-and-weather-card {
    display: grid;
    grid-template-columns: 1fr 200px;
    gap: 20px;
  }
  /* .alert-card {
    background-color: #0f6274;
    border-radius: 25px;
    color: white;
    height: 250px;
    display: grid;
    grid-template-columns: 200px 1fr;
  } */
}

@media (min-width: 1400px) {
  /* .alert-card {
    background-color: #0f6274;
    border-radius: 25px;
    color: white;
    height: 250px;
    display: grid;
    grid-template-columns: 200px 1fr;
  } */
}

@media (min-width: 1600px) {
}

.week_dates .react-multi-carousel-list ul li {
  width: auto !important;
}

/* apex chart */
.apexcharts-toolbar {
  top: -40px !important;
  right: 12px !important;
}

.reset-button-graph {
  stroke: red;
  color: red;
}

.graph-spiner svg {
  position: absolute;
  top: 10%;
  left: 70%;
  width: 1.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.loading .graph-spiner svg {
  position: absolute !important;
  top: 30% !important;
  left: 35% !important;
  width: 2.25em !important;
  transform-origin: center !important;
  animation: rotate4 2s linear infinite;
}

.graph-spiner svg circle {
  fill: none;
  stroke: #6e8192;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.apexcharts-zoom-icon {
  display: none;
  visibility: hidden;
}

.renew-app-allowance img {
  width: 210px;
  height: 163px;
}

/* if content is big then set proper with */
.ellipsis-class {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



.static-block-dashboard{
  background: #216375;
  border-radius: 6px;
  padding: 10px;
  width: 133.75px;
  height: 62.15px;
  color: white;
  cursor: pointer;
}