.dashbaord-card {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 16px -14px rgb(0 0 0 / 75%);
}

.dashboard-card-right-content {
  display: flex;
  flex-direction: column;
}
.dashboard-card-right-content span {
  font-size: 22px;
  font-weight: 600;
}

.dashboard-card-icon-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.dashoard-icon {
  font-size: 19px;
}

.Farmers {
  background-color: #00ffca24;
}

/* .Farmers:hover{
    background-color: #00ffcc65;
} */
.Subscription {
  background-color: #05bfdb24;
}
.Sensors {
  background-color: #08839526;
}
.Device {
  background-color: #0a4d6826;
}
.farmers-icon {
  color: #05dbae;
}
.subscription-icon {
  color: #05bfdb;
}
.sensors-icon {
  color: #088395;
}
.device-icon {
  color: #0a4d68;
}

/* main dashboard */

.super-admin-dashbaord {
  width: 100%;
  padding-top: 10px;
  position: relative;
}

/* packages */
.Subscription-plans-section {
  width: 100%;
  padding-top: 10px;
  position: relative;
}

.packages-table-section .super-admin-packages {
  background-color: white;
  margin-top: 15px;
}

.plan-list-table {
  padding: 10px 7px;
}

.delete-package {
  color: red;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
}

.delete-package:hover {
  transform: scale(1.1);
  background-color: #ffe6e6;
}



.subscription-wrappers
.subscription-btn{
  background: #84c22638;
  padding: 1px 9px;
  color:#3c6500;
  border: 1px solid #95ce41ad;
  border-radius: 5px;
}

.currentPackage-style{
  background-color: #add17757;
}

.hasPackage{
  background-color: #ecf2f4;
}


.go-back{

  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.go-back:hover{
  background: #d9e9cba1;
}


.remove-btn-row{
  position: absolute;
  top: -16px;
  right: -5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}



.dashboard-card-wrapper{
  display: grid;
  grid-template-columns: repeat(4 , 1fr);
  gap: 20px;
}

@media (max-width: 1190px) {
  .dashboard-card-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 782px) {
  .dashboard-card-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 400px) {
  .dashboard-card-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}




