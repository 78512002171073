* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-size: 14px;
  background-color: #f1f7f7;
  /* direction: rtl; */
  /* user-select: none; */
}

a {
  text-decoration: none;
  color: none !important;
}

.arrow_box_text {
  background-color: red !important;
  color: white !important;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: red; */
  border: 1px solid #a9b6c2;
  background-color: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a9b6c2;
}

:root {
  /* fonts */
  --font-roboto: Roboto;
  /* font sizes */
  --font-size-5xs: "10px";
  --font-size-4xs: 11px;
  --font-size-3xs: 12px;
  --font-size-2xs: 14px;
  --font-size-xs: 15px;
  --font-size-sm: 16px;
  --font-size-base: 18px;
  --font-size-lg: 20px;
  --font-size-xl: 30px;
  /* Colors */
  --color-white: #fff;
  --color-teal-100: #0f6274;
  --color-gray-100: #a9b6c2;
  --color-gray-200: #a6a1a1;
  --color-gray-300: #162635;
  --color-gray-400: #111;
  --color-gray-500: rgba(255, 255, 255, 0.83);
  --color-gray-600: rgba(217, 217, 217, 0);
  --color-gray-700: rgba(169, 182, 194, 0.5);
  --color-gray-800: rgba(188, 181, 181, 0.37);
  --color-black: #000;
  --color-red: #ff0000;
  /* Gaps */
  --gap-sm: 17px;
  --gap-md: 40px;
  /* Paddings */
  --padding-md: 50px;
  /* border radiuses */
  --br-2xs: 2px;
  --br-xs: 10px;
  --br-sm: 11px;
  --br-md: 15px;
  --br-lg: 20px;
  --br-xl: 30px;
}

/* eRROR MESSAGE COLOR */
.Errormessage {
  color: #c41717;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5f5f5 !important;
}

.date.selected {
  background-color: yellow;
  color: black;
}

.Loader-wraper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-option {
  /* background-color: #e9eef0; */
  color: rgb(185, 56, 56);
}

.centered-text .ant-picker-input input {
  text-align: center;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: #216375 !important;
  color: white;
}

.dashboard-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4.5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pop-up-container {
  background-color: #216375;
  width: 550px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}

.pop-up-container img {
  width: 200px;
  height: 200px;
}

.pop-up-container h2 {
  font-size: 28px;
}
.pop-up-container h5 {
  font-size: 18px;
}

.apexcharts-tooltip {
  background: rgb(33, 99, 117);
  color: white;
  padding: 5px 10px;
}

/* modal css */

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.custom-modal {
  top: auto !important;
  left: auto !important;
  transform: translate(-50%, -50%) !important;
}

.custom-file-input {
  position: relative;
  /* display: inline-block; */
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.custom-file-input span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.custom-file-input input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
  opacity: 0;
  cursor: pointer;
}

#passwordInput::-ms-reveal {
  display: none;
}

#passwordInput::-ms-clear {
  display: none;
}

/* modal scrollbar */

.h-wraper-65 {
  max-height: 60vh;
  overflow-y: auto;
  /* padding-right: 14px; */
  padding-top: 5px !important;
}
/* width */
.h-wraper-65::-webkit-scrollbar {
  width: 5px;
  padding: 10px;
}

/* Track */
.h-wraper-65::-webkit-scrollbar-track {
  /* background-color: #f5f5f5; */
  border-radius: 0 20px 20px 0;
  /* border: 1px solid #bcb5b5; */
  padding: 10px 0;
  width: 6px;
  background-color: #eeeeee;
}

/* Handle */
.h-wraper-65::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  width: 6px;
  padding: 2px;
  border-radius: 20px;
}

/* Handle on hover */
.h-wraper-65::-webkit-scrollbar-thumb:hover {
  background: #9d9d9d;
}

/* ===== Scrollbar CSS Browser Wise ===== */
/* Firefox */
.h-wraper-65 {
  scrollbar-width: auto;
  scrollbar-color: #c4c4c4 #ffffff;
}

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
    width: 16px;
  } */

/* *::-webkit-scrollbar-track {
    background: #ffffff;
  } */

/* *::-webkit-scrollbar-thumb {
    background-color: #8f54a0;
    border-radius: 10px;
    border: 3px solid #ffffff;
  } */

:where(.css-dev-only-do-not-override-26rdvq).ant-select-status-error:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: red !important;
}

.no-border-input {
  border: none;
  outline: none;
  box-shadow: none;
}

.special-option-class {
  /* Add your custom styling for the special option here */
  font-weight: bold !important;
  /* background-color: #a6c1c8 !important; */
  border-bottom: 1px solid #a6c1c8 !important;
  color: #174552 !important;
}

.timepicker .ant-picker-footer {
  display: none;
}
.timepicker {
  padding-bottom: 10px;
}

.custom-checkbox-input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid red;
  background-color: blue;
}



.optional-timing {
  color: rgb(217, 212, 212) !important;
}

.optional-timing::before {
  content: "";
  width: 100px;
  height: 1px;
  background-color: rgb(217, 212, 212);
  margin: 9px 10px 9px 0;
}
.optional-timing::after {
  content: "";
  width: 100px;
  margin: 9px 0px 9px 10px;
  height: 1px;
  background-color: rgb(217, 212, 212);
}

.custom-timepicker-hide .ant-picker-panel-input {
  overflow: hidden;
}

.custom-timepicker-hide .ant-picker-input {
  width: 100%;
  overflow: hidden;
}

.custom-timepicker-hide .ant-picker-suffix {
  overflow: hidden;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
  .ant-picker-panel
  > .ant-picker-time-panel {
  padding-bottom: 10px !important;
}

.invalid-field .ant-select-selector {
  border-color: #dc3545 !important;
}

.select-input-customized {
  cursor: pointer;
}

.email-varified-icon {
  width: 100px;
  height: 100px;
  background-color: #4bb5432f;
  border-radius: 50%;
  color: #3db334;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.email-varified-icon div {
  font-size: 55px;
}

.email-varifed-section div p {
  font-size: 16px;
  color: rgba(17, 17, 17, 0.846);
}

.email-varifed-section div span {
  color: #3db334;
  font-size: 25px;
}

/* .email-varifed-section div span */

.back-btn {
  border: none;
  background-color: #216375;
  border-radius: 5px;
  color: white;
  padding: 6px 20px;
  /* background-color: #F1F7F7; */
}

:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown
  .ant-picker-time-panel-column::after {
  height: 50px !important;
}

.units-field-wrapper-section {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: transparent !important;
  gap: 15px;
  border-radius: 8px;
  width: 100%;
  padding: 10px 0;
  /* overflow-x: auto; */
  /* padding: 20px !important; */
  margin-bottom: 7px !important;
  /* border: 1px solid #d2d2d24d; */
}



.units-field-wrapper-section div {
  width: 100%;
  height: auto;

}

.unit-field-card {
  background-color: white;
  padding: 9px 9px;
  width: 300px;
  border-radius: 8px;
  border: 1px solid #d2d2d23d;
}

.unit-field-Icon {
  width: 70px !important;
  height: 50px !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cef4f4;
  margin-right: 5px;
  padding: 3px;
}
.unit-field-Icon h3 {
  color: #266564;
  margin: 0;
  font-size: 18px;
  text-transform: uppercase;
}

.unit-filed-title-count p {
  /* padding-top: 5px; */
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  margin: 0 0 5px 0;
}

.unit-filed-title-count span {
  font-size: 16px;
  font-weight: 500;
padding-top: -3px;
  color: #000000;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.loader_2 {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  margin: 8px auto;
  position: relative;
  color: #000;
  box-sizing: border-box;
  animation: animloader 1.5s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}






/* global query  */

@media (max-width: 1190px) {
  .units-field-wrapper-section {
    grid-template-columns: repeat(3, 1fr);
  }
 
}

@media (max-width: 782px) {
  .units-field-wrapper-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 530px) {
  .units-field-wrapper-section {
    grid-template-columns: repeat(1, 1fr);
  }
}


.carousel-item_carousel ul{
  width: 100% !important;
}


/* .dashboard-rangepicker :where(.css-dev-only-do-not-override-26rdvq).ant-picker-range{
  flex-direction: column !important;
}

.dashboard-rangepicker :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-suffix{
  position: absolute !important;
  left: 5px !important;
  top: 20px !important;

}

.dashboard-rangepicker  .ant-picker-suffix  span svg{
  color: white !important;
}

.dashboard-rangepicker  .ant-picker-clear   {
  color: white !important;
  background: transparent !important;
}

.dashboard-rangepicker  .ant-picker-clear:hover{
  color: rgba(255, 255, 255, 0.76) !important;
  background: transparent !important;
}

.dashboard-rangepicker  .ant-picker-active-bar{
  left:0px !important;
  background-color: transparent !important;
}


.dashboard-rangepicker :where(.css-dev-only-do-not-override-26rdvq).ant-picker{
  background-color: #216375 !important;
  border: 1px solid #216375 !important;
}

.dashboard-rangepicker :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-input >input{
  color: white !important;
}
.dashboard-rangepicker :where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-input >input::placeholder {
  color: rgba(255, 255, 255, 0.507) !important;
} */

/* Styles for .ant-picker-range */
.dashboard-rangepicker .ant-picker-range {
  flex-direction: column !important;
}

/* Styles for .ant-picker .ant-picker-suffix */
.dashboard-rangepicker .ant-picker .ant-picker-suffix {
  position: absolute !important;
  left: 5px !important;
  top: 20px !important;
}

/* Styles for icons inside .ant-picker .ant-picker-suffix span svg */
.dashboard-rangepicker .ant-picker .ant-picker-suffix span svg {
  color: white !important;
}

/* Styles for clear button inside .ant-picker */
.dashboard-rangepicker .ant-picker-clear {
  color: white !important;
  background: transparent !important;
}

/* Hover styles for clear button inside .ant-picker */
.dashboard-rangepicker .ant-picker-clear:hover {
  color: rgba(255, 255, 255, 0.76) !important;
  background: transparent !important;
}

/* Styles for .ant-picker-active-bar */
.dashboard-rangepicker .ant-picker-active-bar {
  left: 0px !important;
  background-color: transparent !important;
}

/* Styles for .ant-picker */
.dashboard-rangepicker .ant-picker {
  background-color: #216375 !important;
  border: 1px solid #216375 !important;
}

/* Styles for text input inside .ant-picker .ant-picker-input */
.dashboard-rangepicker .ant-picker .ant-picker-input > input {
  color: white !important;
}

/* Styles for placeholder text inside .ant-picker .ant-picker-input */
.dashboard-rangepicker .ant-picker .ant-picker-input > input::placeholder {
  color: rgba(255, 255, 255, 0.507) !important;
}





 /* Style for the unchecked radio button */
/* input[type="radio"] {
 
  -webkit-appearance: none; 
  appearance: none;
  width: 12px; 
  height: 12px;
  border: 1px solid #236175; 
  border-radius: 50%; 
  outline: none; 
} */

/* Style for the checked radio button */
/* input[type="radio"]:checked:after {
  content: "";
  width: 8px; 
  height: 8px; 
  background-color: #236175; 
  border-radius: 50%; 
  display: block;
  margin: 1px; 
}  */

.bg-blue{
  background-color: #081d34;
}




